/* eslint-disable @typescript-eslint/naming-convention */
import React, { Fragment, ReactNode, RefObject, useEffect, useRef, useState } from 'react';
import { RxCross2 } from 'react-icons/rx';

import { AddIcon } from '../../assets/svgs/addIcon';
import { AddProjectIcon } from '../../assets/svgs/addProject';
import CartIcon from '../../assets/svgs/cartIcon';
import { DownloadButtonIcon } from '../../assets/svgs/downloadButtonIcon';
import LinkIcon from '../../assets/svgs/linkIcon';
import LocationIcon from '../../assets/svgs/location';
import { MenuIcon } from '../../assets/svgs/menuIcon';
import Rating from '../../assets/svgs/rating';
import UserAvatar from '../../assets/svgs/userAvatar';
import WhiteRatings from '../../assets/svgs/whiteRatings';
import { baseURL } from '../../utils/baseUrl';
import { handlePDFDownload } from '../../utils/pdf';
import SantizedHTMLRawData from '../../utils/sanitizeHTML';
import { AISummary } from '../AISummary/Index';
import { generatePageTemplate } from '../ProjectEvaluation/Header';
import AccordionRow from '../shared/AccordionRow/AccordionRow';
import Breadcrumb from '../shared/Breadcrumb/Breadcrumb';
import ButtonContainer from '../shared/ButtonContainer';
import { Container } from '../shared/Container';
import CustomLinkButton from '../shared/CustomLinkButton';
import { OptionType } from '../shared/CustomSelect';
import HubSpotForm from '../shared/HubspotForm';
import { FORMTYPE } from '../shared/HubspotForm/formConfiguration';
import Loader from '../shared/Loader/loader';
import { ModalComponent } from '../shared/Modal/Modal';
import ProductContributionGridHeader from '../shared/ProductContributionGridHeader/ProductContributionGridHeader';

import { ContactUs } from './components/';
import HubspotFormContainer from './HubspotFormContainer';
import ModalBody from './modalBody';
import { ModalFooter } from './modalFooter';
import { ModalHeader } from './modalHeader';
import { MODALTYPE } from './modaltypes';

import style from './productDetails.module.scss';
const LENS_ATTRIBUTE = 'General';
const LENS_ATTRIBUTE_KEY = 'Product Page';
interface AccordionData {
  id: number;
  value: boolean;
}

const buttonInfoIcons: { [key: string]: JSX.Element } = {
  plus: <AddIcon isProductDetail />,
  download: <DownloadButtonIcon isProductDetail />,
  certificate: <WhiteRatings isProductDetail />,
  comment: <UserAvatar />
};
const menuIcons: { [key: string]: JSX.Element } = {
  plus: <MenuIcon />,
  comment: <MenuIcon />
};

interface ProductDetailProps {
  data: Record<string, any> | undefined;
  pdfRef: RefObject<HTMLDivElement>;
}
interface AddProjectHandlersProps {
  addToProject: (params: any) => Promise<any>;
  getProjects: () => Promise<any>;
  createProject: (params: any) => Promise<any>;
}
export const ProductDetail: React.FC<ProductDetailProps> = ({ data, pdfRef }) => {
  const [accordionContentShow, setaccordionContentShow] = useState<AccordionData[]>([]);
  const [modalType, setModalType] = React.useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  const [isPdfLoading, setIsPdfLoading] = useState(false);
  const [addProjectHandlers, setAddProjectHandlers] = useState<AddProjectHandlersProps | null>(
    null
  );
  const [showFields, setshowFields] = useState({
    addProject: false,
    ratingSytem: false
  });
  const [selectedOption, setSelectedOption] = useState<OptionType | null>(null);
  const [addProjectName, setAddProjectName] = useState<string>('');
  const [addProjectZipcode, setAddProjectZipcode] = useState<string | number>('');
  const modalRef = useRef<HTMLDivElement | null>(null);
  const [productPageURl, setProductPageURl] = useState<string | null>('');
  const actionButtonList =
    (data &&
      data['action-buttons'] &&
      data['action-buttons']?.filter((el: any) => el.type !== MODALTYPE.DOCUMENT_CONTRIBUTIONS)) ||
    [];
  const hubSpotFormHiddenFields =
    (data &&
      data['action-buttons'] &&
      data['action-buttons']?.find((el: any) => el.type === MODALTYPE.CONTACT_MANUFACTURER)?.[
        'hubspot-modal'
      ]?.['hidden-fields']) ||
    {};
  const actionButtonRegWall =
    data &&
    data['action-buttons'] &&
    data['action-buttons']?.find((el: any) => el.type !== 'download-pdf');
  const onHeaderClick = (index: number): any => {
    setaccordionContentShow((prevData) =>
      prevData?.map((data) => ({
        ...data,
        value: Number(data.id) === Number(index) ? !data.value : data.value
      }))
    );
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent): void => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        closeModal();
      }
    };

    if (isModalOpen) {
      document.body.addEventListener('mousedown', handleClickOutside);
    } else {
      document.body.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.body.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isModalOpen]);
  useEffect(() => {
    if (data) {
      const generalItem = data?.lens?.find((item: any) => item.name === LENS_ATTRIBUTE);

      if (generalItem) {
        const productPageAttribute = generalItem?.details?.details?.find(
          (attr: any) => attr.name === LENS_ATTRIBUTE_KEY
        );
        const productPageUrl = productPageAttribute ? (productPageAttribute.value as string) : null;

        setProductPageURl(productPageUrl);
      }

      setaccordionContentShow(
        data.lens?.map((_: any, index: number) => ({
          id: index,
          value: true
        })) || []
      );

      if (data && data['action-buttons']) {
        setAddProjectZipcode(
          data['action-buttons']?.find(
            (item: any) => item?.type === MODALTYPE.DOCUMENT_CONTRIBUTIONS
          )?.zipcode || ''
        );
      }
    }
  }, [data]);
  const downloadPdf = (): void => {
    handlePDFDownload(
      pdfRef,
      setIsPdfLoading,
      generatePageTemplate,
      `${data?.product['brand-name']?.toLowerCase()}-${data?.product.sku}-ecomedes-evaluation`,
      data?.ecomedes_logo
    );
  };
  const getAddProjectHandlers = (handlers: any): void => {
    setAddProjectHandlers(handlers);
  };
  const closeModal = (): void => {
    setSelectedOption(null);
    setModalType(null);
    setIsModalOpen(false);
    setshowFields({
      addProject: false,
      ratingSytem: false
    });
    setAddProjectHandlers(null);
  };
  const onSubmitHandler = async (): Promise<any> => {
    if (modalType === MODALTYPE.ADD_TO_PROJECT) {
      if (
        selectedOption &&
        selectedOption?.value !== 'Create new project' &&
        addProjectHandlers?.addToProject
      ) {
        const selectedProjectId = selectedOption?.id;
        const params = { 'selected-project': selectedProjectId };
        const response = await addProjectHandlers?.addToProject(params);
        // Call the trackFn method add Project
        const selectedProject =
          data &&
          data['action-buttons'] &&
          data['action-buttons']?.find((el: any) => el?.type === 'add-to-project');

        if (selectedProject?.trackFn) {
          selectedProject?.trackFn('add-product', {
            id: selectedOption?.id,
            products: [[data?.product?.['brand-ident'], data?.product?.sku]]
          });
        }

        if (response) {
          setSelectedOption(null);
          closeModal();
        }
      }

      if (addProjectHandlers?.createProject && addProjectName && addProjectZipcode) {
        const params = {
          'project-name': addProjectName,
          'project-zipcode': addProjectZipcode
        };

        try {
          await addProjectHandlers.createProject(params).then(() => {
            setAddProjectName('');
            setAddProjectZipcode('');
            closeModal();

            // Call the trackFn method createProject
            const createNewProject =
              data &&
              data['action-buttons'] &&
              data['action-buttons']?.find((el: any) => el?.type === 'add-to-project');

            if (createNewProject?.trackFn) {
              createNewProject?.trackFn('create', {
                // id: selectedOption?.id,
                zipcode: addProjectZipcode,
                products: [[data?.product?.['brand-ident'], data?.product?.sku]]
              });
            }
          });
        } catch (error) {
          console.error('Error creating project:', error);
        }
      }
    }

    if (modalType === MODALTYPE.DOCUMENT_CONTRIBUTIONS) {
      const zipCode: string | number = addProjectZipcode;

      if (data && data['rating-systems'] && selectedOption) {
        const id = data['rating-systems']?.find(
          (data: any) => data?.name === selectedOption?.value
        )?.id;

        if (id) {
          let pathname = window.location.pathname;

          pathname = pathname.replace('product', 'products');

          window.location.href = baseURL({ id, pathname, zipCode });
        }
      }
    }
  };

  interface ModalContent {
    header: ReactNode;
    body: ReactNode;
    footer: ReactNode;
  }

  type ModalContentMap = {
    [key: string]: ModalContent;
  };

  const modalContent: ModalContentMap = {
    [MODALTYPE.ADD_TO_PROJECT]: {
      header: (
        <ModalHeader
          Icon={<AddProjectIcon />}
          label="Add to project"
          description="Create a new project or add to an existing project"
          closeIcon={<RxCross2 fontSize={20} />}
          setIsModalOpen={() => closeModal()}
        />
      ),
      body: (
        <ModalBody
          addProject={showFields.addProject}
          setshowFields={setshowFields}
          addProjectHandlers={addProjectHandlers}
          setSelectedOption={setSelectedOption}
          selectedOption={selectedOption}
          projectName={addProjectName}
          setProjectName={setAddProjectName}
          postalCode={addProjectZipcode}
          setPostalCode={setAddProjectZipcode}
          modalType={modalType || ''}
        />
      ),
      footer: <ModalFooter onClose={() => closeModal()} onSubmit={onSubmitHandler} />
    },
    [MODALTYPE.CONTACT_MANUFACTURER]: {
      header: (
        <ModalHeader
          label={`Contact ${data?.product['brand-name'] || ''}`}
          closeIcon={<RxCross2 fontSize={20} />}
          setIsModalOpen={() => closeModal()}
          className={style.modalHeader}
        />
      ),
      body: (
        <HubspotFormContainer className={style.hubspotFormContainer}>
          <HubSpotForm
            setIsModalOpen={() => closeModal()}
            formId={FORMTYPE.CONTACT_MANUFACTURER}
            data={hubSpotFormHiddenFields || {}}
          />
        </HubspotFormContainer>
      ),
      footer: null
    },
    [MODALTYPE.DOCUMENT_CONTRIBUTIONS]: {
      header: (
        <ModalHeader
          Icon={<Rating productDetail />}
          label="Credit Evaluation"
          description="Check credits for LEED, WELL, Living Building Challenge and other ratings systems and standards. "
          closeIcon={<RxCross2 fontSize={20} />}
          setIsModalOpen={() => closeModal()}
        />
      ),
      body: (
        <ModalBody
          ratings
          addProject={showFields.addProject}
          ratingsData={(data && data['rating-systems']) || []}
          setSelectedOption={setSelectedOption}
          modalType={modalType || ''}
          postalCode={addProjectZipcode}
          setPostalCode={setAddProjectZipcode}
        />
      ),
      footer: <ModalFooter onClose={() => closeModal()} onSubmit={onSubmitHandler} />
    }
  };
  const openModal = async (modalType: string): Promise<void> => {
    if (modalType === MODALTYPE.ADD_TO_PROJECT) {
      if (actionButtonRegWall && actionButtonRegWall?.regWall) {
        const regWall = await actionButtonRegWall?.regWall();

        if (regWall) {
          setModalType(modalType);
          setshowFields({
            addProject: true,
            ratingSytem: false
          });

          setIsModalOpen(true);
        }
      }
    } else if (modalType === MODALTYPE.DOCUMENT_CONTRIBUTIONS) {
      if (actionButtonRegWall && actionButtonRegWall?.regWall) {
        const regWall = await actionButtonRegWall?.regWall();

        if (regWall) {
          setModalType(modalType);

          setshowFields({
            addProject: false,
            ratingSytem: true
          });

          setIsModalOpen(true);
        }
      }
    }

    if (modalType === MODALTYPE.CONTACT_MANUFACTURER) {
      setModalType(modalType);
      const contactManufacture =
        data &&
        data['action-buttons'] &&
        data['action-buttons']?.find((el: any) => el?.type === 'contact-manufacturer');

      if (contactManufacture?.trackFn) {
        contactManufacture?.trackFn('contact');
      }

      setIsModalOpen(true);
    }
  };
  const getModalContent = (): ModalContent =>
    modalType ? modalContent[modalType] : { header: null, body: null, footer: null };

  return (
    <Fragment>
      {data?.product?.banner && data?.product?.banner['image-url'] && (
        <img
          src={data?.product?.banner['image-url']}
          alt="banner-img"
          width={'100%'}
          className={`${style.productEvalBanner} hidePdfElements`}
        />
      )}

      <nav className="hidePdfElements">
        <Breadcrumb breadcrumbs={data?.breadcrumbs || []} />
      </nav>
      <header>
        <div className={style.productEvaluationSection}>
          <div className={style.productMedaDataSection}>
            <div className={`${style.notVisibleLogo} displayPdfBrandLogo`}>
              <img
                className="pdfBrandLogo"
                src={data?.pdf_logo || data?.ecomedes_logo || ''}
                alt="logo"
              />
            </div>
            <Container>
              <div className={style.productDetails}>
                <div className={style.productImgSection}>
                  <img src={data?.product?.image} alt="product" className={style.productImg} />
                </div>
                <div className={style.productMetaDataContainer}>
                  <div className={style.productMetaData}>
                    <div className={style.productCategory}>
                      {!!data?.product['key-features'] &&
                        data.product['key-features']?.map((feature: any, index: number) => (
                          <div className={style.productCategorykeys} key={index}>
                            <div className={style.productCategoryInformation}>{feature?.name}:</div>
                            <div className={style.productCategoryValues}>
                              {Array.isArray(feature?.value) ? (
                                <div>{feature.value.join(', ')}</div>
                              ) : (
                                <div>{feature?.value}</div>
                              )}
                            </div>
                          </div>
                        ))}
                    </div>
                    <div className={style.productBrandName}>
                      {data?.product['brand-name'] || ''}
                    </div>
                    <div className={style.productName}>{data?.product?.name || ''}</div>
                    <div className={style.productDescription}>
                      {data?.product?.description && (
                        <SantizedHTMLRawData content={data?.product?.description || ''} />
                      )}
                    </div>
                    <div className={`${style.downloadButtons} hidePdfElements`}>
                      {productPageURl && (
                        <CustomLinkButton
                          Icon={<LinkIcon />}
                          text={'visit brand page'}
                          buttonClassname={style.brandPage}
                          link={productPageURl || ''}
                          {...(data?.trackFn && {
                            onClick: () => {
                              data?.trackFn('brand-page');
                            }
                          })}
                        />
                      )}
                      {data && data['buy-online-url'] ? (
                        <CustomLinkButton
                          Icon={<CartIcon />}
                          text={'buy online'}
                          buttonClassname={style.buyOnline}
                          {...(data?.trackFn && {
                            onClick: () => {
                              data.trackFn('purchase');
                            }
                          })}
                          link={data['buy-online-url']}
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </Container>
          </div>
        </div>
      </header>
      <div className={`${style.actionButtonContainer}  pdfactionButtonSection`}>
        <Container>
          <div className={`${style.actionButtonsSection} pdfactionButtonSection pdfContentHide`}>
            {data &&
              actionButtonList?.map((info: any, Index: number) => (
                <ButtonContainer
                  key={Index}
                  Icon={
                    info?.type === 'download-pdf' && isPdfLoading ? (
                      <Loader />
                    ) : (
                      buttonInfoIcons[info.icon] ?? ''
                    )
                  }
                  text={info.text === 'Get PDF' ? 'DOWNLOAD PDF' : info.text ?? ''}
                  MenuIcon={menuIcons[info.icon] ?? ''}
                  openModal={info?.type !== 'download-pdf' ? openModal : null}
                  modalType={info?.type !== 'download-pdf' && info?.type}
                  handlers={info?.handlers || ''}
                  projectHandlers={getAddProjectHandlers}
                  {...(info?.type === 'download-pdf' && {
                    onClick: async () => {
                      if (actionButtonRegWall && actionButtonRegWall?.regWall) {
                        const regWall = await actionButtonRegWall?.regWall();

                        if (regWall) {
                          downloadPdf();

                          if (info?.type === 'download-pdf') {
                            info?.trackFn && info?.trackFn('products/pdf');
                          }
                        }
                      }
                    }
                  })}
                />
              ))}
          </div>
        </Container>
      </div>
      <ContactUs trackFn={data?.trackFn} openModal={openModal} />
      <main>
        <div className={style.ratingSystem}>
          <Container>
            <div className={style.aiSummarySection}>
              <AISummary
                data={{
                  ai_summary: data?.ai_summary,
                  green_tags: data?.green_tags
                }}
              />
            </div>
            <h3
              className={`${data?.ai_summary || data?.green_tags?.length ? style.ratingSystemHeader : style.ratingsHeader}`}>
              Details
            </h3>
            <div className={style.projectLocationSection}>
              <div className={style.projectLocation}>
                <div className={style.locationIcon}>
                  <LocationIcon />
                </div>
                <p className={style.projectLocationLabel}>
                  Is this your project location? Edit the location at the top of the page for plant
                  specific information
                </p>
              </div>
              <p className={style.projectPostalCode}>{`Postal Code: ${addProjectZipcode || ''}`}</p>
            </div>
            {!!data?.lens &&
              data?.lens?.map((lensValue: any, lensIndex: number): any => (
                <div style={{ marginBottom: '2rem' }} key={`lens-${lensIndex}`}>
                  <ProductContributionGridHeader
                    key={`header-${lensIndex}`}
                    name={lensValue?.name || ''}
                    image={lensValue?.image || ''}
                    notes={lensValue?.notes || ''}
                    lensDetails={lensValue?.details?.details || lensValue?.details?.locations || []}
                    productPage
                    onHeaderClick={onHeaderClick}
                    index={lensIndex}
                    accordionContentShow={accordionContentShow}
                  />

                  <div className={style.productTable}>
                    <div
                      className={`${
                        accordionContentShow[lensIndex]?.value
                          ? `${style.contentShow} ${style.transition}`
                          : `${style.contentHide} ${style.transition}`
                      } pdfContentShow`}>
                      {lensValue?.details?.details ? (
                        <div>
                          {lensValue?.details?.details.map(
                            (detailsData: any, detailsIndex: number) => (
                              <AccordionRow
                                key={`row-${detailsData}-${detailsIndex}`}
                                title={detailsData?.name || ''}
                                value={detailsData?.value || ''}
                                detailsData={detailsData}
                                type={detailsData?.kind || ''}
                                {...(detailsData?.hint && { tooltipText: detailsData?.hint })}
                                isLensData
                                isLightContent
                                regWall={data?.regWall}
                                trackFn={data?.trackFn}
                                isSignedIn={data?.isSignedIn}
                                product={data?.product}
                              />
                            )
                          )}
                          <div
                            onClick={() => onHeaderClick(lensIndex)}
                            className={`${style.accordionFooter} pdfContentHide`}>
                            {accordionContentShow[lensIndex]?.value ? 'SHOW LESS ' : 'SHOW MORE'}
                          </div>
                        </div>
                      ) : (
                        lensValue?.details?.locations?.length > 0 && (
                          <div>
                            {lensValue?.details?.locations.map(
                              (locationDetails: any, locationIndex: number) => (
                                <AccordionRow
                                  key={`row-${locationDetails}-${locationIndex}`}
                                  city={locationDetails?.city || ''}
                                  postRc={locationDetails?.post_rc || ''}
                                  preRc={locationDetails?.pre_rc || ''}
                                  state={locationDetails?.state || ''}
                                  zipcode={locationDetails?.zipcode || ''}
                                  index={locationIndex}
                                  isManufactureLocation
                                />
                              )
                            )}
                            <div
                              onClick={() => onHeaderClick(lensIndex)}
                              className={`${style.accordionFooter} pdfContentHide`}>
                              {accordionContentShow[lensIndex]?.value ? 'SHOW LESS ' : 'SHOW MORE'}
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
              ))}
          </Container>
        </div>
      </main>
      <ModalComponent
        ref={modalRef}
        isOpen={isModalOpen}
        {...(modalType === MODALTYPE.CONTACT_MANUFACTURER && { className: style.ModalClass })}
        setIsModalOpen={closeModal}
        modalHeader={getModalContent()?.header || null}
        modalBody={getModalContent()?.body || null}
        modalFooter={getModalContent()?.footer || null}
      />
    </Fragment>
  );
};
